//@import 'compass';
//@import 'compass/support';
@import 'mixin';
/* ======================================================================
 p_top
====================================================================== */
.day-01 {
  .u_pc {
    @include mq_max_768 {
      display: none;
    }
  }

  .u_sp {
    display: none;
    @include mq_max_768 {
      display: block;
    }
  }
}
/*covid*/
.covid_wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 15px 0;
  }

  img {
    max-width: 530px;
    transition: all 0.3s linear;
    @include sp {
      width: 100%;
      height: auto;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  a {
    margin: 15px;
    @include sp {
      margin: 0 0 15px;
      line-height: 0;
    }
  }
}

.slider-3 {
  margin: 30px 0 0;
  @include mq_min_769 {
    width: 1100px;
    margin: 30px auto 0;
  }

  &__item {}

  &__link {
    display: block;
  }

  &__img {
    max-width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }

  &__img-wrap {
    width: 100%;
  }

  &__txt {
    background: #fff;
    // min-height: 64px;
    padding: 15px;
    font-size: 15px;
    color: #5A5249;
  }

  .slick-slide {
    margin: 0 5px;
    @include mq_min_769 {
      margin: 0 21px;
    }
  }

  .slide_dots {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    @include mq_min_769 {
      bottom: -65px;
    }

    li {
      display: inline-block;
      margin: 0 10px;

      button {
        position: relative;
        text-indent: -9999px;

        &:before {
          content: '';
          font-size: 20px;
          text-indent: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          background: #8A9178;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button:before {
          content: '';
          background: #5a5249;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-arrow {
    font-size: 0;
    position: absolute;
    bottom: -60px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
    z-index: 2;
    top: inherit;
    transform: inherit;
    @include mq_min_769 {
      bottom: -90px;
      box-shadow: none;
    }

    &.slick-next,
    &.slick-prev {
      width: 40px;
      height: 40px;
      @include mq_min_769 {
        width: 66px;
        height: 66px;
      }
    }

    &.slick-prev {
      background: url("../img/common/btn_arrow_left.png") no-repeat center center/contain;
      left: 4%;
      @include mq_min_769 {
        background: url("../img/common/btn_arrow_left.png") no-repeat center center/contain;
        left: 300px;
      }
    }

    &.slick-next {
      background: url("../img/common/btn_arrow_right.png") no-repeat center center/contain;
      right: 4%;
      @include mq_min_769 {
        background: url("../img/common/btn_arrow_right.png") no-repeat center center/contain;
        right: 300px;
      }
    }
  }
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.slider-btn-wrap {
  margin: 80px 0 0;
  padding: 0 4%;
  @include mq_min_769 {
    margin: 140px 0 0;
    padding: 0;
    text-align: center;
  }
}

.mv-mg {
  margin: 100px auto 60px;
  @include mq_max_768 {
    margin: 80px auto 60px;
  }
}

.top .sec_about {
  padding: 0;

  &::before {
    display: none;
  }

  .link-box01 {
    background: #efe9df;
    padding: 30px 0 10px;

    .bnr_block {
      max-width: 1300px;
      margin: 0 auto;
      position: inherit;
      left: inherit;
      transform: inherit;
      bottom: inherit;

      a {
        max-width: 532px;
        line-height: 0;
        margin: 0 0 20px;

        &:nth-child(2n) {
          margin: 0 0 20px 30px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
//no-webp

body.no-webp {
  .top .characteristic ul li._01:after {
    background: url("../img/p_top/sp/characteristic_li_bg_01.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._02:after {
    background: url("../img/p_top/sp/characteristic_li_bg_03.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._03:after {
    background: url("../img/p_top/sp/characteristic_li_bg_08.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._04:after {
    background: url("../img/p_top/sp/characteristic_li_bg_05.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._05:after {
    background: url("../img/p_top/sp/characteristic_li_bg_06.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._06:after {
    background: url("../img/p_top/sp/characteristic_li_bg_07.png") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }
}
//webp

body.webp {
  .top .characteristic ul li._01:after {
    background: url("../img/p_top/sp/characteristic_li_bg_01.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._02:after {
    background: url("../img/p_top/sp/characteristic_li_bg_03.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._03:after {
    background: url("../img/p_top/sp/characteristic_li_bg_08.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._04:after {
    background: url("../img/p_top/sp/characteristic_li_bg_05.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._05:after {
    background: url("../img/p_top/sp/characteristic_li_bg_06.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .top .characteristic ul li._06:after {
    background: url("../img/p_top/sp/characteristic_li_bg_07.webp") no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

body.no-webp .map_box:after,
body.webp .map_box:after {
  @include sp {
    display: none;
  }
}

.top .main_visual {
  @include sp {
    height: 650px;
  }

  .accepting_txt {
    padding: 0 !important;
    background: none !important;
    @include sp {
      padding: 6px 12px;
    }

    a.mv_a {
      padding: 15px 40px;
      transition: all 0.3s linear;
      background: #29af4b;
      border-radius: 5px;
      box-shadow: 0 1px 1px 0 rgba(55, 41, 12,0.3);
      z-index: 1;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.panels .year_2012 .stomach .graph {
  background: url("../img/p_top/stomach_44.jpg") no-repeat;
}

.panels .year_2012 .colono .graph {
  background: url("../img/p_top/colono_55.jpg") no-repeat;
}

.panels .year_2012 .endo .graph {
  background: url("../img/p_top/endo_49.png") no-repeat;
}

.panels .year_2013 .endo .graph {
  background: url("../img/p_top/endo_49.png") no-repeat;
}

.panels .year_2014 .endo .graph {
  background: url("../img/p_top/endo_66.png") no-repeat;
}

.panels .year_2014 .stomach .graph {
  background: url("../img/p_top/stomach_55.jpg") no-repeat;
}

.panels .year_2014 .colono .graph {
  background: url("../img/p_top/colono_42.jpg") no-repeat;
}

.panels .year_2015 .endo .graph {
  background: url("../img/p_top/endo_66.png") no-repeat;
}

.panels .year_2016 .stomach .graph {
  background: url("../img/p_top/stomach_55.jpg") no-repeat;
}

.panels .year_2016 .colono .graph {
  background: url("../img/p_top/colono_42.jpg") no-repeat;
}

.panels .year_2016 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.panels .year_2017 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.panels .year_2018 .endo .graph {
  background: url("../img/p_top/endo_66.png") no-repeat;
}

.panels .year_2019 .stomach .graph {
  background: url("../img/p_top/stomach_50.png") no-repeat;
}

.panels .year_2019 .colono .graph {
  background: url("../img/p_top/colono_50.png") no-repeat;
}

.panels .year_2019 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.panels .year_2020 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.graph p.small {
  font-size: 28px;
}

.panels .year_2020 .stomach .graph {
  background: url("../img/p_top/colono_50.png") no-repeat;
}

.panels .year_2020 .colono .graph {
  background: url("../img/p_top/colono_50.png") no-repeat;
}

.panels .year_2021 .stomach .graph {
  background: url("../img/p_top/stomach_50.png") no-repeat;
}

.panels .year_2021 .colono .graph {
  background: url("../img/p_top/colono_50.png") no-repeat;
}

.panels .year_2021 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.top .top_colonoscopy ul li.nomi_icon a:before {
  content: url("../img/p_top/nomi.png");
}

.panels .year_2022 .stomach .graph {
  background: url("../img/p_top/stomach_67.png") no-repeat;
}

.panels .year_2022 .colono .graph {
  background: url("../img/p_top/colono_66.jpg") no-repeat;
}

.panels .year_2022 .endo .graph {
  background: url("../img/p_top/endo_90.png") no-repeat;
}

.topdata .js_tab_btn {
  width: 9%;
  @include sp {
    width: 28%;
  }
}

.topdata .graph {
  position: relative;
}

.topdata .graph ._circle {
  top: 0;
  left: 0;
}

.topdata .graph ._circle span {
  margin: 0 0 0 -7px;
  font-weight: normal;
  line-height: 120px !important;
}

.topdata .graph ._circle canvas {
  position: absolute;
  left: -5px;
  top: -5px;
}

.topdata .panels .colono .graph,
.topdata .panels .endo .graph,
.topdata .panels .stomach .graph {
  position: relative;
  background: none;
}

.dv_2.w800 {
  max-width: 800px;
  margin: 0 auto;
}

.dv_2.w820 {
  max-width: 820px;
  margin: 0 auto;
}
//
//
//
//
//
//
//
//
//